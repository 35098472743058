import React, { useState, useContext } from "react";
import {
  Grid,
  Button,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateMultipleItemPrice } from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import CloseCross from "../../../components/SVG/CloseCross";
import {
  AvenirBlackH4,
  AvenirBlackH3,
  H4,
  H6,
  DotText,
} from "../../../utils/text";
import styles from "../../../styles/index";
import { CenterModal, SingleSelect, InputNumber } from "../../../components";
import colors from "../../../styles/colors";
import { replaceWithDynamicText } from "../../../utils/util";
import { RightPointingArrow } from "../../../components/SVG";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: "0 8px",
    padding: "15px 25px",
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    "& th": {
      fontWeight: "bold",
      border: "none",
      padding: "12px 8px",
    },
  },

  scrollableTable: {
    margin: "0px 5px",
    overflowY: "auto",
    maxHeight: "60vh",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#004C60",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#FAFAFA",
    },
  },
  dataRow: {
    cursor: "pointer",
    backgroundColor: "#FAFAFA",
    "& td": {
      border: "none",
      padding: "12px 8px",
    },
  },
  cell1: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
    width: "50%",
  },
  cell2: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
    width: "25%",
  },
  cell3: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
    width: "25%",
  },
}));
const AssignPriceToItems = ({ open, onClose, items, callback }) => {
  const style = styles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const [operationCode, setOperationCode] = useState({
    id: null,
    operation: null,
  });
  const [nextScreenFlag, setNextScreenFlag] = useState(false);
  const [priceChangeFactor, setPriceChangeFactor] = useState(null);
  const [modifiedListOfItemsPrice, setModifiedListOfItemsPrice] = useState([]);
  const listOfPriceChangeOptions = [
    {
      operation: Context.langData.increase_by_value,
      id: 0,
    },
    {
      operation: Context.langData.increase_by_percentage,
      id: 1,
    },
    {
      operation: Context.langData.decrease_by_value,
      id: 2,
    },
    {
      operation: Context.langData.decrease_by_percentage,
      id: 3,
    },
    {
      operation: Context.langData.set_dollar_amount,
      id: 4,
    },
  ];

  const handleMultiplePriceUpdate = () => {
    if (modifiedListOfItemsPrice.length > 0) {
      dispatch(
        updateMultipleItemPrice({ items: modifiedListOfItemsPrice }, callback)
      );
    }
  };
  const handleItemPriceCalculation = () => {
    if (!operationCode.operation || !priceChangeFactor) {
      return;
    }

    const floatOfPriceChangeFactor = parseFloat(priceChangeFactor);

    const modifiedPriceOfItems = items.map((item) => {
      const floatOfCurrentItemPrice =
        item.price === null ? 0.0 : parseFloat(item.price);

      let updatedPrice;

      switch (operationCode.id) {
        case 0:
          updatedPrice = floatOfCurrentItemPrice + floatOfPriceChangeFactor;
          break;

        case 1:
          updatedPrice =
            floatOfCurrentItemPrice * (1 + floatOfPriceChangeFactor / 100);
          break;

        case 2:
          updatedPrice = floatOfCurrentItemPrice - floatOfPriceChangeFactor;
          break;

        case 3:
          updatedPrice =
            floatOfCurrentItemPrice * (1 - floatOfPriceChangeFactor / 100);
          break;

        case 4:
          updatedPrice = floatOfPriceChangeFactor;
          break;

        default:
          updatedPrice = floatOfCurrentItemPrice;
      }

      updatedPrice = Math.max(updatedPrice, 0);

      return {
        id: item.item_id,
        name: item.name,
        current_price: floatOfCurrentItemPrice,
        update_price: updatedPrice,
      };
    });

    setNextScreenFlag(true);
    setModifiedListOfItemsPrice(modifiedPriceOfItems);
  };

  const onChangeOfOperation = (e) => {
    setOperationCode({
      operation: e.target.value,
      id: listOfPriceChangeOptions.findIndex(
        (i) => i.operation === e.target.value
      ),
    });
  };

  return (
    <CenterModal open={open} padding={1}>
      <Grid
        data-qaid='assignCategories__body_grid'
        style={{
          display: "flex",
          height: "75vh",
          flexDirection: "column",
          width: 700,
          borderRadius: "5px",
        }}>
        <Grid
          data-qaid='assignCategories__child1_grid'
          style={{
            display: "flex",
            height: "95%",
            flexDirection: "column",
            overflowX: "auto",
            paddingRight: 7,
          }}>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Grid>
              <H6
                data-qaid='assignCategories__title'
                style={{ margin: "20px 36px 10px 36px" }}
                label={
                  !nextScreenFlag
                    ? Context.langData.edit_price
                    : Context.langData.confirm_price_changes
                }
              />
            </Grid>
            <Grid data-qaid='assignCategories__close_grid'>
              <IconButton onClick={onClose} style={{ marginRight: 29 }}>
                <CloseCross data-qaid='assignCategories__closeicon' size={20} />
              </IconButton>
            </Grid>
          </Grid>
          {!nextScreenFlag ? (
            <H4
              data-qaid='assignCategories__desc'
              style={{
                marginLeft: 36,
                marginRight: 36,
                marginBottom: 15,
              }}
              label={
                items.length === 1
                  ? replaceWithDynamicText(
                      Context.langData.item_selected_price_change,
                      {
                        items: items.length,
                      }
                    )
                  : replaceWithDynamicText(
                      Context.langData.items_selected_price_change,
                      {
                        items: items.length,
                      }
                    )
              }
            />
          ) : (
            <H4
              data-qaid='assignCategories__desc'
              style={{
                marginLeft: 36,
                marginRight: 36,
                marginBottom: 15,
              }}
              label={Context.langData.please_review_price_changes}
            />
          )}

          <Divider />
          {!nextScreenFlag && (
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}>
              <Grid
                container
                direction='column'
                style={{
                  marginTop: 10,
                  marginLeft: 36,
                  marginBottom: 40,
                  marginRight: 36,
                  width: "70%",
                }}>
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <AvenirBlackH4 label={Context.langData.pricing_rule} />
                </Grid>

                <SingleSelect
                  id='id'
                  list={listOfPriceChangeOptions}
                  value={operationCode.operation}
                  valueKey='operation'
                  displayKey='operation'
                  placeholder={Context.langData.select_one}
                  handleChange={onChangeOfOperation}
                  border={false}
                  height={56}
                />
              </Grid>
              <Grid
                container
                direction='column'
                style={{
                  marginTop: 10,
                  marginLeft: 36,
                  marginBottom: 40,
                  marginRight: 36,
                }}>
                <InputNumber
                  startAdornment={
                    operationCode.id === 1 || operationCode.id === 3 ? (
                      <InputAdornment position='end'>%</InputAdornment>
                    ) : (
                      <InputAdornment position='start'>$</InputAdornment>
                    )
                  }
                  value={priceChangeFactor}
                  onChange={(e) => setPriceChangeFactor(e.target.value)}
                  id
                  placeholder={Context.langData.value}
                  inputPlaceholder={Context.langData.value}
                  isPlaceHolder
                  type='number'
                  height='50px'
                  style={{ width: "30%" }}
                />
              </Grid>
            </Grid>
          )}
          {nextScreenFlag && (
            <TableContainer className={classes.scrollableTable}>
              <Table className={classes.table} aria-label='data table'>
                <TableHead>
                  <TableRow className={`${classes.headerRow} `}>
                    {["Item", "Current price", "Update price"].map(
                      (str, index) => (
                        <TableCell className={classes.cell} key={index}>
                          <AvenirBlackH3 label={str} />
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modifiedListOfItemsPrice.map((item) => (
                    <TableRow className={classes.dataRow}>
                      <TableCell className={classes.cell}>
                        <DotText label={item.name} />
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0px 10px 0px 10px",
                          }}>
                          <DotText
                            label={`$${item.current_price.toFixed(2)}`}
                          />
                          <RightPointingArrow color='#C3C3C3' />
                        </Grid>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <DotText label={`$${item.update_price.toFixed(2)}`} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid
          data-qaid='assignCategories_cancel_main_Grid'
          style={{
            display: "flex",
            height: "10%",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}>
          <Grid
            data-qaid='assignCategories_cancelGrid'
            item
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              backgroundColor: "#FAFAFA",
              width: "100%",
              paddingTop: "25px",
              alignItems: "center",
              paddingBottom: "25px",
              paddingLeft: "16px",
              border: "1px solid #C3C3C3",
            }}>
            {/* <AvenirRedH4
              data-qaid='assignCategory_request_errorHeading'
              label={errorFlag}
            /> */}
            {!nextScreenFlag && (
              <>
                <Button
                  className={style.buttonClass}
                  data-qaid='assignCategories_cancelButton'
                  style={{
                    backgroundColor: "#FFF",
                    border: "2px solid #051D33",
                    marginRight: 20,
                    marginLeft: 20,
                    width: "45%",
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={onClose}>
                  <AvenirBlackH4
                    data-qaid='assignCategories_cancelLabel'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='assignCategories_saveButton'
                  style={{
                    backgroundColor: !(
                      operationCode.operation && priceChangeFactor
                    )
                      ? colors.greyText
                      : "#FFB600",
                    color: "white",
                    marginRight: 30,
                    width: "45%",
                  }}
                  disabled={!(operationCode.operation && priceChangeFactor)}
                  variant='contained'
                  id='cancel'
                  type='submit'
                  onClick={handleItemPriceCalculation}>
                  <AvenirBlackH4
                    data-qaid='assignCategories_saveLabel'
                    label={Context.langData.next}
                  />
                </Button>
              </>
            )}
            {nextScreenFlag && (
              <>
                <Button
                  className={style.buttonClass}
                  data-qaid='assignCategories_cancelButton'
                  style={{
                    backgroundColor: "#FFF",
                    border: "2px solid #051D33",
                    marginRight: 20,
                    marginLeft: 20,
                    width: "45%",
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={() => setNextScreenFlag(false)}>
                  <AvenirBlackH4
                    data-qaid='assignCategories_cancelLabel'
                    label={Context.langData.back}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='assignCategories_saveButton'
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                    marginRight: 30,
                    width: "45%",
                  }}
                  variant='contained'
                  id='cancel'
                  type='submit'
                  onClick={handleMultiplePriceUpdate}>
                  <AvenirBlackH4
                    data-qaid='assignCategories_saveLabel'
                    label={Context.langData.confirm_changes}
                  />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CenterModal>
  );
};

export default AssignPriceToItems;
