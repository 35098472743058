import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { RoutedVerticalTabs } from "../../../components";
import MyContext from "../../../Provider/MyContext";
import NoPermissionPage from "../../../components/NoPermission";
import { FeatureKeys } from "../../../enums";

const General = () => {
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const navigate = useNavigate();
  const location = useLocation();

  // Define tabs
  const Tabs = [
    {
      key: "offers",
      route: "discounts",
      name: Context.langData.discounts,
      id: 0,
    },
    { key: "shifts", route: "shifts", name: Context.langData.shifts, id: 1 },
    {
      key: "printers",
      route: "printers",
      name: Context.langData.printers,
      id: 2,
    },
    {
      key: "reservation_settings",
      route: "kitchens",
      name: Context.langData.kitchen_creation,
      id: 3,
    },
    {
      key: "delivery_options",
      route: "delivery-options",
      name: Context.langData.delivery_options,
      id: 4,
    },
    {
      key: "End_of_Day_Setting",
      route: "end-of-day-settings",
      name: Context.langData.Reports,
      id: 5,
    },
  ];

  // Add feature flag-based tab dynamically
  const featureFlagClient = useLDClient();
  if (featureFlagClient) {
    const flagValue = featureFlagClient.variation(
      FeatureKeys.KitchenTicket,
      false
    );
    if (flagValue) {
      Tabs.push({
        key: "Kitchen_Guest_Receipt_Setting",
        route: "kitchen-guest-receipt-settings",
        name: Context.langData.kitchen_tickets,
        id: 6,
      });
    }
  }

  // Permission mapping
  const isItemHasPermission = useMemo(
    () =>
      Tabs.map((tab) => {
        const permission = userPermissions.find((perm) => perm.key === tab.key);
        return {
          ...tab,
          isPermission: permission?.status || false,
        };
      }),
    [userPermissions, Tabs]
  );

  // Determine the selected tab based on the current location
  const selectedIndex = Tabs.findIndex((tab) =>
    location.pathname.includes(tab.route)
  );

  // Handle tab change
  const handleTabChange = (index) => {
    const selectedTab = isItemHasPermission[index];
    if (selectedTab) {
      if (selectedTab.isPermission) {
        navigate(`/home/settings/general/${selectedTab.route}`);
      } else {
        navigate("/home/settings/general/no-permission");
      }
    }
  };

  return (
    <Grid
      data-qaid='ordertypes_index_div'
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
      <Grid
        data-qaid='menu_grid'
        container
        direction='row'
        style={{ borderTop: "1px solid #EEEEEE" }}>
        {/* Vertical Tabs */}
        <Grid
          data-qaid='menu_tabs'
          item
          xs={12}
          lg={2}
          style={{ backgroundColor: "#FAFAFA" }}>
          <RoutedVerticalTabs
            tabData={Tabs}
            indexCallback={handleTabChange}
            selectedIndex={selectedIndex === -1 ? 0 : selectedIndex}
            style={{ marginTop: 24 }}
          />
        </Grid>

        {/* Outlet or NoPermissionPage */}
        <Grid
          data-qaid='menu_options_addbutton'
          item
          xs={12}
          lg={10}
          style={{ marginTop: 10 }}>
          {selectedIndex !== -1 &&
          isItemHasPermission[selectedIndex]?.isPermission ? (
            <Outlet />
          ) : (
            <NoPermissionPage />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default General;
