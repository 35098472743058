import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useLDClient } from "launchdarkly-react-client-sdk";
import VerticalTabs from "../../../components/verticalTabs";
import {
  fetchOrderType_ps,
  fetchOrderTypes,
  fetchPaymentTypes,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import { FeatureKeys } from "../../../enums";
import SalesSummaryTabs from "./salesSummary";
import DaywiseSales from "./daywiseSales";
import ItemSales from "./itemSales";
import AlcoholSales from "./alcoholSales";
import ModifierSales from "./modifierSales";
import PettyCash from "./pettyCash";
import Groups from "./groups";
import EmployeeSales from "./employeeSalesReport";

const SalesReport = () => {
  const Context = useContext(MyContext);
  const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
  const dispatch = useDispatch();
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const callback = (value) => {
    setSelectIndexTabChange(value);
  };
  useEffect(() => {
    dispatch(fetchPaymentTypes());
    dispatch(fetchOrderType_ps());
    dispatch(fetchOrderTypes());
  }, []);
  const TabsData = [
    { name: Context.langData.sales_summary, id: 0 },
    { name: Context.langData.day_wise_sales, id: 1 },
    { name: Context.langData.items_sales, id: 2 },
    { name: Context.langData.alcohol_sales, id: 3 },
    { name: Context.langData.modifier_sales, id: 4 },
    { name: Context.langData.employee_sales, id: 5 },
    { name: Context.langData.groups, id: 6 },
    { name: Context.langData.petty_cash, id: 7 },
  ];
  const featureFlagClient = useLDClient();
  if (featureFlagClient && selectedRestaurant && selectedRestaurant.rest_id) {
    const context = {
      kind: "restaurant",
      key: "restaurant",
      restId: selectedRestaurant.rest_id,
    };
    featureFlagClient.identify(context, null, () => {});

    const pettyCashFlagValue = featureFlagClient.variation(
      FeatureKeys.PettyCash,
      true
    );

    if (pettyCashFlagValue === false) {
      // Remove pettyCash from TabsData if it's not available
      const pettyCash = (element) => element.id === 7;
      const index = TabsData.findIndex(pettyCash);
      TabsData.splice(index, 1);
    }
  }

  return (
    <Grid data-qaid='sales_mainContainer' container style={{ padding: 20 }}>
      <Grid
        item
        xs={12}
        lg={2}
        style={{ display: "flex", backgroundColor: "#FAFAFA" }}>
        <VerticalTabs tabData={TabsData} indexCallback={callback} />
      </Grid>
      <Grid
        data-qaid='sales_subContainer'
        item
        xs={12}
        sm={12}
        md={9}
        lg={10}
        style={{ paddingLeft: 10 }}>
        {selectIndexTabChange === 0 && <SalesSummaryTabs />}
        {selectIndexTabChange === 1 && <DaywiseSales />}
        {selectIndexTabChange === 2 && <ItemSales />}
        {selectIndexTabChange === 3 && <AlcoholSales />}
        {selectIndexTabChange === 4 && <ModifierSales />}
        {selectIndexTabChange === 6 && <Groups />}
        {selectIndexTabChange === 7 && <PettyCash />}
        {selectIndexTabChange === 5 && <EmployeeSales />}
      </Grid>
    </Grid>
  );
};

export default SalesReport;
