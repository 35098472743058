import React, { useContext } from "react";
import { Button, Grid } from "@material-ui/core";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { H6, AvenirBlackH4 } from "../../utils/text";

import MyContext from "../../Provider/MyContext";

const Settings = () => {
  const Context = useContext(MyContext);
  const location = useLocation();
  const navigate = useNavigate();

  const settingsList = [
    {
      key: "general",
      title: Context.langData.general,
      route: "settings/general/discounts",
      id: 0,
    },
    {
      key: "payments",
      route: "settings/payments/taxes",
      title: Context.langData.payments,
      id: 1,
    },
  ];
  const handleNavigation = (route) => {
    navigate(`/home/${route}`);
  };

  return (
    <Grid
      data-qaid='settings_index_grid'
      style={{
        backgroundColor: "#FFFFFF",
        minHeight: "100%",
        width: "100%",
        overflow: "hidden",
      }}>
      {/* Header Section */}
      <Grid
        data-qaid='settings_index_contentgrid'
        style={{ height: "20%", marginBottom: 28 }}>
        <Grid item xs={12} style={{ marginLeft: 36 }}>
          <H6
            label={Context.langData.settings}
            style={{ color: "#051D33", paddingTop: 36, fontWeight: "800" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginLeft: 36, marginTop: 36, display: "flex" }}>
          {settingsList.map((item, i) => (
            <Button
              key={item.route}
              style={{
                backgroundColor: location.pathname.includes(item.key)
                  ? "#051D33"
                  : "#EEEEEE",
                borderRadius: i === 0 ? "4px 0 0 4px" : "0 4px 4px 0px",
              }}
              onClick={() => handleNavigation(item.route)}>
              <AvenirBlackH4
                label={item.title}
                style={{
                  color: location.pathname.includes(item.key)
                    ? "#FFFFFF"
                    : "#051D33",
                  padding: "10px 16px",
                  lineHeight: "24px",
                }}
              />
            </Button>
          ))}
        </Grid>
      </Grid>

      {/* Render Selected View */}
      <Grid style={{ height: "80%" }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Settings;
