import React, { useContext, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { Button, Grid } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import { H6, AvenirBlackH4 } from "../../utils/text";
import MyContext from "../../Provider/MyContext";
import NoPermissionPage from "../../components/NoPermission";
import { FeatureKeys } from "../../enums";
import SalesReports from "./sales";
import InventoryReports from "./inventory";
import EmployeeReports from "./employeeReports";

const Reports = () => {
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const navigate = useNavigate();
  const location = useLocation();

  const reportsList = [
    {
      key: "sale_reports",
      title: Context.langData.sales_report,
      id: 0,
      route: "reports/sales-reports",
      visible: true,
    },
    {
      key: "inventory_reports",
      title: Context.langData.inventoryreport,
      id: 1,
      route: "reports/inventory-reports",
      visible: true,
    },
    {
      key: "employee_reports",
      title: Context.langData.employee_reports,
      id: 2,
      route: "reports/employee-reports",
      visible: true,
    },
  ];
  useEffect(() => {
    handleSelectItem(reportsList[0]);
  }, []);
  const selectedIndex = reportsList.findIndex((tab) =>
    location.pathname.includes(tab.route)
  );

  const featureFlagClient = useLDClient();
  if (featureFlagClient && selectedRestaurant && selectedRestaurant.rest_id) {
    const context = {
      kind: "restaurant",
      key: "restaurant",
      restId: selectedRestaurant.rest_id,
    };
    featureFlagClient.identify(context, null, () => {
      console.log("New context's flags available");
    });

    const flagValue = featureFlagClient.variation(FeatureKeys.Inventory, true);
    if (flagValue === false) {
      reportsList[1].visible = false;
    }
  }
  // eslint-disable-next-line consistent-return
  const isItemHasPermission = useMemo(
    () =>
      reportsList.map((tab) => {
        if (tab.key) {
          const permission = userPermissions.find(
            (perm) => perm.key === tab.key
          );
          return {
            ...tab,
            isPermission: permission?.status || false,
          };
        }
        return { ...tab, isPermission: true };
      }),
    [userPermissions, reportsList]
  );

  const handleSelectItem = (item) => {
    navigate(`/home/${item.route}`);
  };

  // eslint-disable-next-line consistent-return
  const renderSelectedView = () => {
    if (location.pathname.includes("sales-reports")) {
      if (isItemHasPermission[0].isPermission) {
        return <SalesReports data-qaid='reports_salesreport' />;
      }
      return <NoPermissionPage data-qaid='reports_salesreport_nopermissions' />;
    }
    if (location.pathname.includes("inventory-reports")) {
      if (reportsList[1].visible && isItemHasPermission[1].isPermission) {
        return <InventoryReports data-qaid='reports_inventoryreport' />;
      }
      return (
        <NoPermissionPage data-qaid='reports_inventoryreport_nopermissions' />
      );
    }
    if (location.pathname.includes("employee-reports")) {
      // employess reports is available accessible
      return <EmployeeReports data-qaid='reports_Employee' />;
    }
  };
  return (
    <Grid
      data-qaid='reports_main_grid'
      style={{ backgroundColor: "#FFFFFF", minHeight: "100%", width: "100%" }}>
      <Grid data-qaid='reports_content_grid' style={{ height: "20%" }}>
        <Grid
          data-qaid='reports_title_grid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36 }}>
          <H6
            data-qaid='reports_title_label'
            label={Context.langData.reports}
            style={{ color: "#051D33", paddingTop: 36 }}
          />
        </Grid>
        <Grid
          data-qaid='reports_buttons_grid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ margin: 36, flexDirextion: "row" }}>
          {reportsList
            .filter((item) => item.visible)
            .map((item, i) => (
              <Button
                key={i}
                data-qaid='reports_button'
                style={{
                  backgroundColor:
                    selectedIndex === item.id ? "#051D33" : "#EEEEEE",
                  marginRight: 1,
                  borderRadius: 0,
                  borderTopLeftRadius: i === 0 ? 4 : 0,
                  borderBottomLeftRadius: i === 0 ? 4 : 0,
                  borderTopRightRadius: reportsList.length - 1 === i ? 4 : 0,
                  borderBottomRightRadius: reportsList.length - 1 === i ? 4 : 0,
                }}
                onClick={() => handleSelectItem(item)}>
                <AvenirBlackH4
                  data-qaid='reports_button_label'
                  label={item.title}
                  style={{
                    color: selectedIndex === item.id ? "#FFFFFF" : "#051D33",
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 10,
                    paddingBottom: 10,
                    lineHeight: "24px",
                  }}
                />
              </Button>
            ))}
        </Grid>
        <Grid data-qaid='reports_border' item xs={12} sm={12} md={12} lg={12} />
        <Grid
          data-qaid='transactions_border'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ borderTop: "1px solid #EEEEEE" }}
        />
      </Grid>
      <Grid
        data-qaid='reports_content_view'
        style={{ height: "80%", marginTop: 20 }}>
        {renderSelectedView()}
      </Grid>
    </Grid>
  );
};

export default Reports;
