import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { RoutedVerticalTabs } from "../../../components";
import MyContext from "../../../Provider/MyContext";
import NoPermissionPage from "../../../components/NoPermission";

const Payments = () => {
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const navigate = useNavigate();
  const location = useLocation();

  // Define tabs
  const Tabs = [
    {
      key: "taxes",
      route: "taxes",
      name: Context.langData.taxes,
      id: 0,
    },
    {
      key: "taxes",
      route: "tax-profiles",
      name: Context.langData.taxProfiles,
      id: 1,
    },
    {
      route: "terminals",
      name: Context.langData.terminals,
      id: 2,
    },
  ];

  // Permission mapping
  const isItemHasPermission = useMemo(
    () =>
      Tabs.map((tab) => {
        if (tab.key) {
          const permission = userPermissions.find(
            (perm) => perm.key === tab.key
          );
          return {
            ...tab,
            isPermission: permission?.status || false,
          };
        }
        return {
          ...tab,
          isPermission: true,
        };
      }),
    [userPermissions, Tabs]
  );

  // Determine the selected tab based on the current location
  const selectedIndex = Tabs.findIndex((tab) =>
    location.pathname.includes(tab.route)
  );

  // Handle tab change
  const handleTabChange = (index) => {
    const selectedTab = isItemHasPermission[index];
    if (selectedTab) {
      if (selectedTab.isPermission) {
        navigate(`/home/settings/payments/${selectedTab.route}`);
      } else {
        navigate("/home/settings/payments/no-permission");
      }
    }
  };

  return (
    <Grid
      data-qaid='ordertypes_index_div'
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
      <Grid
        data-qaid='menu_grid'
        container
        direction='row'
        style={{ borderTop: "1px solid #EEEEEE" }}>
        {/* Vertical Tabs */}
        <Grid
          data-qaid='menu_tabs'
          item
          xs={12}
          lg={2}
          style={{ backgroundColor: "#FAFAFA" }}>
          <RoutedVerticalTabs
            tabData={Tabs}
            indexCallback={handleTabChange}
            selectedIndex={selectedIndex === -1 ? 0 : selectedIndex}
            style={{ marginTop: 24 }}
          />
        </Grid>

        {/* Outlet or NoPermissionPage */}
        <Grid
          data-qaid='menu_options_addbutton'
          item
          xs={12}
          lg={10}
          style={{ marginTop: 10 }}>
          {selectedIndex !== -1 &&
          isItemHasPermission[selectedIndex]?.isPermission ? (
            <Outlet />
          ) : (
            <NoPermissionPage />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Payments;
