import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import MyContext from "../../../Provider/MyContext";

const ReportView = ({ isShiftReport = false, isEodReport = false }) => {
  const Context = useContext(MyContext);
  const eodSpecificReport = useSelector(
    (state) => state.eodReportsReducer.specificEodReport
  );
  const eodSpecificUserShiftReport = useSelector(
    (state) => state.eodReportsReducer.specificEodShiftReport
  );
  const [report, setReport] = useState({});
  const getDateFormat = (timestamp) => {
    const dateTime = new Date(timestamp);
    return moment(dateTime).format("MMMM DD, YYYY hh:mm A");
  };
  useEffect(() => {
    if (eodSpecificReport && isEodReport) {
      setReport(eodSpecificReport);
    } else if (eodSpecificUserShiftReport && isShiftReport) {
      setReport(eodSpecificUserShiftReport);
    }
  }, [
    eodSpecificReport,
    isEodReport,
    eodSpecificUserShiftReport,
    isShiftReport,
  ]);

  const handleNegativeValues = (value) =>
    (value / 100).toFixed(2) >= 0
      ? `$${(value / 100).toFixed(2)}`
      : `-$${Math.abs((value / 100).toFixed(2))}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "600px",
        minWidth: "500px",
        height: "100%",
      }}
      className='report-main-container'
      data-qaid='report-main-container'>
      <div
        style={{
          backgroundColor: "#fff",
          width: "100%",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-start",
          }}>
          {isEodReport && (
            <h1
              style={{
                paddingLeft: 40,
                fontSize: 28,
                fontFamily: "AvenirLT-Black",
                color: "#051D33",
              }}
              className='report_heading'
              data-qaid='report_heading'>
              {Context.langData.end_of_day_report}
            </h1>
          )}
          {isShiftReport && (
            <h1
              style={{
                paddingLeft: 40,
                fontSize: 28,
                fontFamily: "AvenirLT-Black",
                color: "#051D33",
              }}
              className='report_heading'>
              {Context.langData.user_report}
            </h1>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              paddingLeft: isShiftReport ? 45 : 40,
              paddingRight: isShiftReport && 40,
            }}>
            {isShiftReport && (
              <h3
                style={{
                  fontSize: 16,
                  fontFamily: "AvenirLT",
                  color: "#051D33",
                }}
                className='date_text'>
                {report?.employee?.employeeName}
              </h3>
            )}
            <h3
              style={{
                fontSize: 16,
                fontFamily: "AvenirLT",
                color: "#051D33",
              }}
              className='date_text'>
              {getDateFormat(report?.executedAt)}
            </h3>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "30px 20px 0px 60px",
        }}>
        {report?.ReportData?.data?.map((item, index) => (
          <div key={index} style={{ width: 370 }}>
            <h3
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontWeight: 900,
                padding: "20px 0 20px 0",
                fontSize: 16,
                fontFamily: "AvenirLT-Medium",
                color: "#051D33",
              }}>
              {item.sectionName}
            </h3>
            <hr
              style={{
                width: "100%",
                border: "none",
                borderTop: "1px solid #EEEEEE",
                marginBottom: "10px",
              }}
            />
            {item?.sectionData.some((obj) =>
              Object.prototype.hasOwnProperty.call(obj, "quantity")
            ) && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}>
                    <h4
                      style={{
                        fontSize: 14,
                        fontFamily: "AvenirLT-Medium",
                        fontWeight: 300,
                        color: "#051D33",
                      }}>
                      {Context.langData.quantity}
                    </h4>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <h4
                      style={{
                        fontSize: 14,
                        fontFamily: "AvenirLT-Medium",
                        fontWeight: 300,
                        color: "#051D33",
                        paddingLeft: 70,
                      }}>
                      {Context.langData.amount}
                    </h4>
                  </div>
                </div>
                <hr
                  style={{
                    width: "100%",
                    border: "none",
                    borderTop: "1px solid #EEEEEE",
                    marginBottom: "5px",
                  }}
                />
              </>
            )}

            {item?.sectionData?.map((subItem) => (
              <>
                {subItem.name.includes("Total") && (
                  <hr
                    style={{
                      width: "100%",
                      border: "none",
                      borderTop: "1px solid #EEEEEE",
                      marginTop: "10px",
                    }}
                  />
                )}
                <div
                  key={subItem.name}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "70%",
                    }}>
                    {subItem.name.includes("Total") ? (
                      <h3
                        style={{
                          fontWeight: 800,
                          fontSize: 16,
                          fontFamily: "AvenirLT-Medium",
                          color: "#051D33",
                        }}>
                        {subItem.name}
                      </h3>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "70%",
                        }}>
                        <h4
                          style={{
                            fontSize: 14,
                            fontFamily: "AvenirLT-Medium",
                            fontWeight: 300,
                            color: "#051D33",
                          }}>
                          {subItem.name}
                        </h4>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "30%",
                    }}>
                    {subItem.name.includes("Total") ? (
                      <h3
                        style={{
                          fontWeight: 800,
                          fontSize: 16,
                          fontFamily: "AvenirLT-Medium",
                          color: "#051D33",
                        }}>
                        {handleNegativeValues(subItem.value.amount)}
                      </h3>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            width: "35%",
                          }}>
                          <h4
                            style={{
                              fontSize: 14,
                              fontFamily: "AvenirLT-Medium",
                              fontWeight: 300,
                              color: "#051D33",
                            }}>
                            {subItem?.quantity}
                          </h4>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "50%",
                          }}>
                          <h4
                            style={{
                              fontSize: 14,
                              fontFamily: "AvenirLT-Medium",
                              fontWeight: 300,
                              color: "#051D33",
                            }}>
                            {handleNegativeValues(subItem.value.amount)}
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportView;
